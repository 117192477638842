import {
  colors,
  // createMuiTheme,
  unstable_createMuiStrictModeTheme as createMuiTheme,
  responsiveFontSizes,
} from "@material-ui/core";
import { THEMES } from "../constants";
import { softShadows, strongShadows } from "./shadows";
import typography from "./typography";

const baseOptions = {
  direction: "ltr",
  typography,
  overrides: {
    MuiInputBase: {
      input: {
        padding: "13.5px 14px",
      },
    },
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: "hidden",
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
      },
    },
    MuiChip: {
      root: {
        backgroundColor: "rgba(0,0,0,0.075)",
      },
    },
    MuiMenu: {
      list: {
        maxHeight: "50vh",
      },
    },
  },
};

const themesOptions = [
  {
    name: THEMES.LIGHT,
    overrides: {
      MuiInputBase: {
        input: {
          "&::placeholder": {
            opacity: 1,
            color: colors.blueGrey[600],
          },
        },
      },
      MuiTableRow: {
        root: {
          "&$selected": {
            backgroundColor: "rgba(29, 148, 206, 0.5)",
            "&:hover": {
              backgroundColor: "rgba(52, 160, 164, 0.5)",
            },
          },
        },
      },
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: "rgba(52, 160, 164, 0.5)",
        },
      },
    },
    palette: {
      type: "light",
      action: {
        active: colors.blueGrey[600],
      },
      background: {
        default: colors.common.white,
        dark: "#f4f6f8",
        paper: colors.common.white,
      },
      primary: {
        main: "#1d94ce",
        light: "#FFFFFF",
        contrastText: "#546e7a",
      },
      secondary: {
        main: "#34a0a4",
      },
      text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600],
      },
      warning: {
        main: '#FF6961'
      }
    },
    shadows: softShadows,
  },
  {
    name: THEMES.ONE_DARK,
    overrides: {
      MuiInputBase: {
        input: {
          "&::placeholder": {
            opacity: 1,
            color: colors.red,
          },
          "&$focused": {
            backgroundColor: "rgba(0, 0, 0, 0.18)",
          },
          "&:-webkit-autofill": {
            "-webkit-box-shadow": "0 0 0 100px #282C34 inset !important",
          },
        },
      },

      MuiOutlinedInput: {
        input: {
          fontSize: "14px",
        },
      },
      MuiTableRow: {
        root: {
          "&$selected": {
            backgroundColor: "rgba(29, 148, 206, 0.5)",
            "&:hover": {
              backgroundColor: "rgba(29, 148, 206, 0.5)",
            },
          },
        },
      },
    },
    palette: {
      type: "dark",
      action: {
        active: "rgba(255, 255, 255, 0.54)",
        hover: "rgba(255, 255, 255, 0.04)",
        selected: "rgba(255, 255, 255, 0.08)",
        disabled: "rgba(255, 255, 255, 0.26)",
        disabledBackground: "rgba(255, 255, 255, 0.12)",
        focus: "rgba(255, 255, 255, 0.12)",
      },
      background: {
        default: "#282C34",
        dark: "#1c2025",
        paper: "#282C34",
      },
      primary: {
        main: "#1d94ce",
        contrastText: "#FFFFFF",
        light: "#282C34",
      },
      secondary: {
        main: "#34a0a4",
      },
      text: {
        primary: "#e6e5e8",
        secondary: "#adb0bb",
      },
      warning: {
        main: '#FF6961'
      }
    },
    shadows: strongShadows,
  },
];

export const createTheme = (config = {}) => {
  let themeOptions = themesOptions.find((theme) => theme.name === config.theme);

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    [themeOptions] = themesOptions;
  }

  let theme = createMuiTheme(
    Object.assign({}, baseOptions, themeOptions)
  );

  theme = responsiveFontSizes(theme);

  return theme;
};
