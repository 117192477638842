import React from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const GuestGuard = ({ children }) => {
  const { isAuthenticated, confirmed } = useAuth();

  if (confirmed !== null && !confirmed) {
    return <Redirect to='/resetpasswordq' />;
  }

  if (isAuthenticated) {
    return <Redirect to='/' />;
  }

  return <>{children}</>;
};

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default GuestGuard;
