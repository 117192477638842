import "./scss/style.default.scss";
import { createBrowserHistory } from "history";
import { BrowserRouter as Router } from "react-router-dom";
// import { Router } from 'react-router-dom';
import { ThemeProvider } from "@material-ui/core";
import setAuthToken from "./utils/setAuthToken";
import { SnackbarProvider } from "notistack";

import routes, { renderRoutes } from "./routing/routes";
import { AuthProvider } from "./contexts/AuthContext";
import useSettings from "./hooks/useSettings";
import { createTheme } from "./theme";
import GlobalStyles from "./components/GlobalStyles";
import ScrollReset from 'src/components/ScrollReset';

import ApolloClient from "apollo-boost";
import { ApolloProvider } from "react-apollo";

const history = createBrowserHistory();

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

const client = new ApolloClient({
  // Change this to the URL of your WordPress site.
  uri: process.env.REACT_APP_WP_URL
});

const App = () => {
  const { settings } = useSettings();
  const theme = createTheme({
    direction: "ltr",
    responsiveFontSizes: true,
    theme: settings.theme,
  });

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider dense maxSnack={3}>
          <Router history={history}>
            <AuthProvider>
              <GlobalStyles />
              <ScrollReset />
              {renderRoutes(routes)}
            </AuthProvider>
          </Router>
        </SnackbarProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
};

export default App;
