import React, { Fragment } from "react";
import PropTypes from "prop-types";
import useAuth from "../hooks/useAuth";
import { Redirect } from "react-router-dom";

const AdminGuard = ({ children }) => {
    const { isAuthenticated, user } = useAuth();
  
    if (!isAuthenticated) {
      return <Redirect to='/login' />;
    }
  
    if (!user.is_admin) {
        return <Redirect to='/' />;
    }

    return <Fragment>{children}</Fragment>;
  };
  
  AdminGuard.propTypes = {
    children: PropTypes.node,
  };
  
  export default AdminGuard;