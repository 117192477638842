import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { SettingsProvider } from "./contexts/SettingsContext";
import "./i18nextConf";
import "react-perfect-scrollbar/dist/css/styles.css";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

console.log("process.env.NODE_ENV", process.env.NODE_ENV);
console.log(new Date());

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: "https://8801f4223f634766af33644d74aa7308@o986122.ingest.sentry.io/5942628",
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <SettingsProvider>
      <App />
    </SettingsProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
