import React, { Fragment } from "react";
import PropTypes from "prop-types";
import useAuth from "../hooks/useAuth";
import { Redirect } from "react-router-dom";

const AuthGuard = ({ children }) => {
  const { isAuthenticated, confirmed, logout } = useAuth();

  if (confirmed !== null && !confirmed) {
    return <Redirect to='/resetpasswordq' />;
  }

  if (!localStorage.token) {
    logout();
  }

  if (!isAuthenticated) {
    return <Redirect to='/login' />;
  }

  return <Fragment>{children}</Fragment>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
