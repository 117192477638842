import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { THEMES } from "../../../constants";
import { useTranslation } from "react-i18next";
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  makeStyles,
  SvgIcon,
  Toolbar,
  Tooltip,
} from "@material-ui/core";
import { Menu as MenuIcon } from "react-feather";
import Logo from "../../../components/Logo";
import Account from "./Account";
import LanguageSwitcher from "./LanguageSwitcher";
import useSettings from "../../../hooks/useSettings";
// import {
//   Brightness7 as LightIcon,
//   Brightness4 as DarkIcon,
// } from "@material-ui/icons";
import { Sun as LightIcon, Moon as DarkIcon } from "react-feather";

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...(theme.name === THEMES.LIGHT
      ? {
          backgroundColor: theme.palette.primary.light,
        }
      : {}),
    ...(theme.name === THEMES.ONE_DARK
      ? {
          backgroundColor: theme.palette.background.default,
        }
      : {}),
  },
  toolbar: {
    minHeight: 64,
  },
  topBarLogo: {
    width: "50%",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
}));

const TopBar = ({ onMobileNavOpen, ...rest }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { settings, saveSettings } = useSettings();
  const [lightTheme, setLightTheme] = useState(settings.theme === THEMES.LIGHT);

  const handleThemeChange = (isLightTheme) => {
    setLightTheme(isLightTheme);
    saveSettings({
      theme: isLightTheme ? THEMES.LIGHT : THEMES.ONE_DARK,
    });
  };

  return (
    <AppBar className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton onClick={onMobileNavOpen}>
            <SvgIcon fontSize='small'>
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Hidden mdDown>
          <RouterLink to='/'>
            <Logo className={classes.topBarLogo} />
          </RouterLink>
        </Hidden>
        <Box ml={2} flexGrow={1} />
        <Box ml={2}>
          {lightTheme ? (
            <Tooltip title={t("Dark")}>
              <IconButton onClick={() => handleThemeChange(false)}>
                <DarkIcon fill='#3A5874' />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title={t("Light")}>
              <IconButton onClick={() => handleThemeChange(true)}>
                <LightIcon color='#D4AF37' />
              </IconButton>
            </Tooltip>
          )}
        </Box>
        <Box ml={2}>
          <LanguageSwitcher />
        </Box>
        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default React.memo(TopBar);
