import React from "react";
import { useState, useRef } from "react";
import { Globe as LanguageIcon } from "react-feather";
import {
  Box,
  Tooltip,
  IconButton,
  SvgIcon,
  Menu,
  MenuItem,
  makeStyles,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

const useStyles = makeStyles((theme) => ({
  popover: {
    width: 200,
  },
}));

const LanguageSwitcher = () => {
  const [isOpen, setOpen] = useState(false);
  const ref = useRef(null);
  const { t } = useTranslation();

  const classes = useStyles();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
    setOpen(false);
  };

  return (
    <>
      <Tooltip title={t("Languages")}>
        <Box
          display='flex'
          alignItems='center'
          component={IconButton}
          onClick={handleOpen}
          ref={ref}
        >
          <SvgIcon fontSize='small' color='primary'>
            <LanguageIcon />
          </SvgIcon>
        </Box>
      </Tooltip>
      <Menu
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        open={isOpen}
        anchorEl={ref.current}
      >
        {i18n.language !== "hu" && (
          <MenuItem onClick={() => handleLanguageChange("hu")}>
            {t("Hungarian")}
          </MenuItem>
        )}
        {i18n.language !== "en" && (
          <MenuItem onClick={() => handleLanguageChange("en")}>
            {t("English")}
          </MenuItem>
        )}
        {i18n.language !== "ro" && (
          <MenuItem onClick={() => handleLanguageChange("ro")}>
            {t("Romanian")}
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default LanguageSwitcher;
