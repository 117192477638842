import AuthGuard from "../components/AuthGuard";
import GuestGuard from "../components/GuestGuard";
import AdminGuard from "../components/AdminGuard";
import { Fragment, lazy, Suspense } from "react";
import LoadingScreen from "../components/LoadingScreen";
import { Redirect, Route, Switch } from "react-router-dom";
import DashboardLayout from "../components/layout";

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  <Component {...props} />
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFoundView")),
  },
  {
    exact: true,
    path: "/login",
    guard: GuestGuard,
    component: lazy(() => import("src/views/auth/LoginPage")),
  },
  {
    exact: true,
    path: "/register",
    guard: GuestGuard,
    component: lazy(() => import("src/views/auth/RegisterPage")),
  },
  {
    exact: true,
    path: "/forgotpassword",
    component: lazy(() => import("src/views/auth/ForgotPassword")),
  },
  {
    exact: true,
    path: "/confirmreset/:resettoken",
    component: lazy(() => import("src/views/auth/ConfirmResetPassword")),
  },
  {
    exact: true,
    path: "/confirmreg/:regtoken",
    guard: GuestGuard,
    component: lazy(() => import("src/views/auth/ActivateRegistration")),
  },
  {
    exact: true,
    path: "/resetpasswordq",
    component: lazy(() => import("src/views/auth/InitResetPassword")),
  },
  {
    exact: true,
    path: "/",
    guard: AuthGuard,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/Dashboard")),
  },
  {
    exact: true,
    path: "/indexes",
    guard: AuthGuard,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/Indexes")),
  },
  {
    exact: true,
    path: "/indexes/history",
    guard: AuthGuard,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/Indexes/IndexHistory")),
  },
  {
    exact: true,
    path: "/invoice",
    guard: AuthGuard,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/invoice")),
  },
  {
    exact: true,
    path: "/invoice/:codclient",
    guard: AuthGuard,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/invoice")),
  },
  {
    exact: true,
    path: "/account",
    guard: AuthGuard,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/account/AccountView")),
  },
  {
    exact: true,
    path: "/management/users",
    guard: AdminGuard,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/users/usersList")),
  },
  {
    exact: true,
    path: "/management/users/temp",
    guard: AdminGuard,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/users/tempUsers")),
  },
  {
    exact: true,
    path: "/management/users/:id",
    guard: AdminGuard,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/users/usersDetails")),
  },
  {
    exact: true,
    path: "/management/payments",
    guard: AdminGuard,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/admin/payments")),
  },
  {
    exact: true,
    path: "/management/invoices",
    guard: AdminGuard,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/admin/invoices")),
  },
  {
    exact: true,
    path: "/payment/confirm",
    guard: AuthGuard,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/Payment/ConfirmPayment")),
  },
  {
    exact: true,
    path: "/payment/mypayments",
    guard: AuthGuard,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/Payment/MyPayments")),
  },
  {
    exact: true,
    path: "/contracts",
    guard: AuthGuard,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/contracts")),
  },
  {
    exact: true,
    path: "/contracts/add",
    guard: AuthGuard,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/contracts/addContract")),
  },
  { component: () => <Redirect to='/404' /> },
];

export default routes;
