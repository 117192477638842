import {
    Box,
    makeStyles,
    Menu,
    MenuItem,
    IconButton,
    SvgIcon,
    Tooltip,
  } from "@material-ui/core";
  import React, { Fragment, useRef, useState } from "react";
  import { useHistory } from "react-router-dom";
  import useAuth from "../../../hooks/useAuth";
  import { useSnackbar } from "notistack";
  import { Settings as SettingsIcon } from "react-feather";
  import { useTranslation } from 'react-i18next';
  
  const useStyles = makeStyles((theme) => ({
    avatar: {
      height: 32,
      width: 32,
      marginRight: theme.spacing(1),
    },
    popover: {
      width: 200,
    },
  }));
  
  const Account = () => {
    const classes = useStyles();
    const history = useHistory();
    const ref = useRef(null);
    const { logout } = useAuth();
    const [isOpen, setOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const {t} = useTranslation();
  
    const handleOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
  
    const handleLogout = async () => {
      try {
        handleClose();
        await logout();
        history.push("/login");
      } catch (err) {
        console.error(err);
        enqueueSnackbar("Unable to logout", {
          variant: "error",
        });
      }
    };
  
    const handleRedirect = (url) => {
      handleClose();
      history.push(url);
    };
  
    return (
      <Fragment>
        <Tooltip title={t("Settings")}>
          <Box
            display='flex'
            alignItems='center'
            component={IconButton}
            onClick={handleOpen}
            ref={ref}
          >
            <SvgIcon fontSize='small' color='primary'>
              <SettingsIcon />
            </SvgIcon>
          </Box>
        </Tooltip>
        <Menu
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          keepMounted
          PaperProps={{ className: classes.popover }}
          getContentAnchorEl={null}
          open={isOpen}
          anchorEl={ref.current}
        >
          <MenuItem onClick={() => handleRedirect("/account")}>
            {t("Settings")}
          </MenuItem>
          <MenuItem onClick={handleLogout}>{t("Logout")}</MenuItem>
        </Menu>
      </Fragment>
    );
  };
  
  export default Account;
  