import {
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { Fragment, useEffect } from "react";
import { useLocation, matchPath } from "react-router-dom";
import PropTypes from "prop-types";
import useAuth from "../../../hooks/useAuth";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link as RouterLink } from "react-router-dom";
import DataUsageIcon from "@material-ui/icons/DataUsage";
import StorageIcon from "@material-ui/icons/StorageOutlined";
import PeopleIcon from "@material-ui/icons/PeopleOutline";
import LocalLibraryIcon from "@material-ui/icons/LocalLibraryOutlined";
import TimeLineIcon from "@material-ui/icons/Timeline";
import DescriptionIcon from "@material-ui/icons/DescriptionOutlined";
import AssignmentIcon from "@material-ui/icons/AssignmentOutlined";
import AddCircleIcon from "@material-ui/icons/AddCircleOutline";
import AccountBalanceWalletOutlinedIcon from "@material-ui/icons/AccountBalanceWalletOutlined";
import {
  HardDrive,
  FileText as RecepitIcon,
  Sliders as SettingsIcon,
} from "react-feather";
import Logo from "../../Logo";

import NavItem from "./NavItem";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 79,
    height: "calc(100% - 128px)",
  },
  welcomeTitle: {
    textAlign: "center",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  welcomeName: {
    textAlign: "center",
    marginBottom: "5px",
    textTransform: "capitalize",
  },
  topBarLogo: {
    width: "100%",
    paddingTop: "10px",
    paddingBottom: "0px",
  },
}));

const getSection = (t) => {
  return [
    {
      subheader: t("Reports"),
      forAdmin: false,
      items: [
        {
          title: t("Dashboard"),
          icon: DataUsageIcon,
          href: "/",
        },
        {
          title: "Index",
          icon: StorageIcon,
          href: "/indexes",
          items: [
            {
              title: t("Reading"),
              icon: LocalLibraryIcon,
              href: "/indexes",
            },
            {
              title: t("Calendar readings"),
              icon: TimeLineIcon,
              href: "/indexes/history",
            },
          ],
        },
        {
          title: t("My Invoices"),
          icon: RecepitIcon,
          href: "/invoice",
        },
        {
          title: t("My Payments"),
          icon: AccountBalanceWalletOutlinedIcon,
          href: "/payment/mypayments",
        },
        {
          title: t("Contracts"),
          icon: AssignmentIcon,
          href: "/contracts",
          items: [
            {
              title: t("My Contracts"),
              icon: DescriptionIcon,
              href: "/contracts",
            },
            {
              title: t("Add Contract"),
              icon: AddCircleIcon,
              href: "/contracts/add",
            },
          ],
        },
      ],
    },
    {
      subheader: "Management",
      forAdmin: true,
      items: [
        {
          title: t("Users"),
          icon: PeopleIcon,
          href: "/management/users",
          items: [
            {
              title: t("List"),
              href: "/management/users",
            },
            {
              title: t("Temp"),
              href: "/management/users/temp",
            },
          ],
        },
        {
          title: t("Payments"),
          icon: HardDrive,
          href: "/management/payments",
        },
        {
          title: t("Invoices"),
          icon: RecepitIcon,
          href: "/management/invoices",
        },
      ],
    },
    {
      subheader: t("Settings"),
      forAdmin: false,
      items: [
        {
          title: t("Settings"),
          href: "/account",
          icon: SettingsIcon,
        },
      ],
    },
  ];
};

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const Navbar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useAuth();
  const { t } = useTranslation();
  const sections = getSection(t);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box height='100%' display='flex' flexDirection='column'>
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} pb={0} display='flex' justifyContent='center'>
            <RouterLink to='/'>
              <Logo className={classes.topBarLogo} />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={3} mt={0}>
          <Box mt={2} mb={2} textAlign='center'>
            <Link
              component={RouterLink}
              to='/account'
              variant='h5'
              color='primary'
              underline='none'
              className={classes.welcomeName}
            >
              {user.nev}
            </Link>
          </Box>
          <Box mt={2} textAlign='center'>
            <Typography variant='body2' color='textSecondary'>
              {t("Üdvözöl a Közüzemek Rt. Sepsiszentgyörgy")}
            </Typography>
          </Box>
          <Box display='flex' justifyContent='center' mt={2}>
            <Link
              component={RouterLink}
              to='/account'
              variant='h5'
              color='textPrimary'
              underline='none'
            >
              {user.firstName} {user.lastName}
            </Link>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {sections.map((section) => {
            if (section.forAdmin) {
              if (user.is_admin) {
                return (
                  <List
                    key={section.subheader}
                    subheader={
                      <ListSubheader disableGutters disableSticky>
                        {section.subheader}
                      </ListSubheader>
                    }
                  >
                    {renderNavItems({
                      items: section.items,
                      pathname: location.pathname,
                    })}
                  </List>
                );
              }
              return null;
            } else {
              return (
                <List
                  key={section.subheader}
                  subheader={
                    <ListSubheader disableGutters disableSticky>
                      {section.subheader}
                    </ListSubheader>
                  }
                >
                  {renderNavItems({
                    items: section.items,
                    pathname: location.pathname,
                  })}
                </List>
              );
            }
          })}
        </Box>
        <Divider />
      </PerfectScrollbar>
    </Box>
  );

  return (
    <Fragment>
      <Hidden lgUp>
        <Drawer
          anchor='left'
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant='temporary'
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor='left'
          classes={{ paper: classes.desktopDrawer }}
          open
          variant='persistent'
        >
          {content}
        </Drawer>
      </Hidden>
    </Fragment>
  );
};

Navbar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default Navbar;
