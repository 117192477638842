export default {
    h1: {
      fontWeight: 500,
      fontSize: 35,
      letterSpacing: '-0.24px',
      fontFamily: 'Poppins'
    },
    h2: {
      fontWeight: 500,
      fontSize: 29,
      letterSpacing: '-0.24px',
      fontFamily: 'Poppins'
    },
    h3: {
      fontWeight: 500,
      fontSize: 24,
      letterSpacing: '-0.06px',
      fontFamily: 'Poppins'
    },
    h4: {
      fontWeight: 500,
      fontSize: 20,
      letterSpacing: '-0.06px',
      fontFamily: 'Poppins'
    },
    h5: {
      fontWeight: 500,
      fontSize: 16,
      letterSpacing: '-0.05px',
      fontFamily: 'Poppins'
    },
    h6: {
      fontWeight: 500,
      fontSize: 14,
      letterSpacing: '-0.05px',
      fontFamily: 'Poppins'
    },
    overline: {
      fontWeight: 500
    },
    button: {
      textTransform: 'initial'
    },
    p: {
      fontFamily: 'Poppins',
      fontSize: 50
    },
    span: {
      fontFamily: 'Poppins'
    },
    body2: {
      fontFamily: 'Poppins',
      fontWeight: 600,
    }
  };
  