import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { THEMES } from "../../../constants";
import { useTranslation } from "react-i18next";
import { Map as MapPinIcon, Phone as PhoneIcon } from "react-feather";
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  makeStyles,
  SvgIcon,
  Toolbar,
  Tooltip,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...(theme.name === THEMES.LIGHT
      ? {
          backgroundColor: theme.palette.secondary.main,
        }
      : {}),
    ...(theme.name === THEMES.ONE_DARK
      ? {
          backgroundColor: theme.palette.background.default,
        }
      : {}),
    top: "inherit",
    bottom: "0",
    boxShadow: "0 0 1px 0 rgb(0 0 0 / 31%), 0 24px 15px 18px rgb(0 0 0 / 93%)",
  },
  toolbar: {
    minHeight: 50,
  },
  footer_text: {
    color: "white",
    display: "inline-block",
    marginLeft: 5,
  },
}));

const Footer = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <AppBar className={classes.root} color='primary'>
      <Toolbar className={classes.toolbar}>
        <Box ml={2} flexGrow={1} />
        <Box className='footer_content' mr={3}>
          <PhoneIcon color='white' size='16px' />
          <Typography className={classes.footer_text} variant='h6'>
            +40 744 326 104
          </Typography>
        </Box>
        <Box className='footer_content'>
          <MapPinIcon color='white' size='16px' />
          <Typography className={classes.footer_text} variant='h6'>
            Cartierul Câmpu Frumos, nr. 5, Sfântu Gheorghe
          </Typography>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Footer;
