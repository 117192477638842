import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

const fallbackLng = ["ro"];
const availableLanguages = ["hu", "ro", "en"];

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({ fallbackLng, debug: false, supportedLngs: availableLanguages });

export default i18n;
