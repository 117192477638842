import React from "react";
import useSettings from "../hooks/useSettings";
import { THEMES } from "../constants";
import { useTranslation } from 'react-i18next';

const Logo = (props) => {
  const { settings } = useSettings();
  const {t, i18n} = useTranslation();

  const dir = settings.theme === THEMES.ONE_DARK ? "dark" : "light";
  const logoLang = i18n.language === 'hu' ? "logo_hu" : "logo_ro";

  const imageUrl = `/static/images/${dir}/${logoLang}.png`;

  return <img className='img-fluid' alt={t('Közüzemek Rt.')} src={imageUrl} {...props} />;
};

export default Logo;
