import React, { createContext, useEffect, useState } from "react";
import { THEMES } from "../constants";

const defaultSettings = {
  theme: THEMES.LIGHT,
};

export const storeSettings = (settings) => {
  window.localStorage.setItem("settings", JSON.stringify(settings));
};

const SettingsContext = createContext({
  settings: defaultSettings,
  saveSettings: () => Promise.resolve(),
});

const getCurrentSettings = () => {
  let settings = null;

  try {
    const storedData = localStorage.getItem("settings");
    if (storedData) {
      settings = JSON.parse(storedData);
    }
  } catch (error) {
    console.error(error);
  }

  return settings;
};

export const SettingsProvider = ({ settings, children }) => {
  const [currentSettings, setCurrentSettings] = useState(
    settings || defaultSettings
  );

  const saveSettings = (update) => {
    setCurrentSettings(update);
    storeSettings(update);
  };

  useEffect(() => {
    const storedSettings = getCurrentSettings();

    if (storedSettings) {
      setCurrentSettings(storedSettings);
    }
  }, []);

  return (
    <SettingsContext.Provider
      value={{
        settings: currentSettings,
        saveSettings,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsContext;
