import axios from "axios";
import React, { createContext, useEffect, useState } from "react";
import SplashScreen from "../components/SplashScreen";
import jwtDecode from "jwt-decode";

const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  token: null,
  user: null,
  confirmed: null,
};

const setSession = (token) => {
  if (token) {
    localStorage.setItem("token", token);
    axios.defaults.headers.common["x-auth-token"] = token;
  } else {
    localStorage.removeItem("token");
    delete axios.defaults.headers.common["x-auth-token"];
  }
};

const isValidToken = (token) => {
  if (!token) return false;

  const decoded = jwtDecode(token);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const AuthContext = createContext({
  ...initialAuthState,
  login: () => Promise.resolve(),
  impersonate: () => Promise.resolve(),
  logout: () => {},
  register: () => {},
  resetPassword: () => {},
  getAuthUser: () => Promise.resolve(),
  setUserData: () => Promise.resolve(),
});

export const AuthProvider = ({ children }) => {
  const [data, setData] = useState(initialAuthState);
  // const [error, setError] = useState(null);

  // const [pending, setPending] = useState(true);

  const logout = () => {
    setSession(null);
    setData({
      ...data,
      isAuthenticated: false,
      token: null,
      user: null,
    });
  };

  const resetPassword = async (email) => {
    logout();

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios.post("/api/auth/resetpassword", { email }, config);
  };

  const register = async (registerModel) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify(registerModel);

    await axios.post("/api/auth/register", body, config);
  };

  const impersonate = async (userid) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios
      .post(`/api/auth/impersonate`, { userid }, config)
      .then(async (resp) => {
        setData({
          ...data,
          token: resp.data,
        });

        setSession(resp.data);

        getAuthUser();
      });
  };

  const login = async (email, password) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({
      email,
      password,
    });

    await axios.post("/api/auth", body, config).then(async (resp) => {
      setData({
        ...data,
        token: resp.data,
      });

      setSession(resp.data);

      getAuthUser();
    });
  };

  const getAuthUser = async () => {
    try {
      await axios.get("/api/auth").then((resp) => {
        if (resp.data.confirmed) {
          setData({
            ...data,
            isAuthenticated: true,
            user: resp.data,
            isInitialised: true,
            confirmed: resp.data.confirmed,
          });
        } else {
          setData({
            ...data,
            isAuthenticated: false,
            user: null,
            isInitialised: true,
            confirmed: resp.data.confirmed,
          });
        }
      });
    } catch (error) {
      setData({
        ...data,
        isAuthenticated: false,
        user: null,
        isInitialised: true,
        token: null,
      });
    }
  };

  const setUserData = (update) => {
    setData({
      ...data,
      user: {
        ...data.user,
        nev: update.user.name,
        phone: update.user.phone
      }
    })
  };

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = localStorage.getItem("token");
        if (accessToken && isValidToken(accessToken)) {
          getAuthUser();
        } else {
          setData({
            ...data,
            isAuthenticated: false,
            user: null,
            isInitialised: true,
          });
        }
      } catch (error) {
        setData({
          ...data,
          isAuthenticated: false,
          user: null,
          isInitialised: true,
        });
      }
    };

    initialize();
  }, []);

  if (!data.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...data,
        login,
        impersonate,
        register,
        logout,
        setUserData,
        resetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
